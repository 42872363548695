import React from "react";
import {Box, Typography, Container, Grid, Button} from "@mui/material";
import {ExploitDogOneIcon, ExploitDogTwoIcon, ExploitDogThreeIcon} from "../Icons";
import {Link} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const arr = [
    {
        title: <>Облачная <br/> платформа</>,
        description: `
        Возможность сканирования географически распределенных и сегментированных сетей
по периметру, в динамических облачных средах и конечных устройствах.
        `,
        icon: <ExploitDogOneIcon
            // @ts-ignore
            sx={{
                width: '100%',
                height: 'auto',
                maxHeight: '400px'
            }}
        />
    },
    {
        title: <>Немедленное <br/> реагирование на угрозы</>,
        description: `
        Сервис позволяет Вам заранее устранять потенциальные угрозы при появлении новых уязвимостей, 
        незамедлительно информируя об опасности в режиме реального времени.
        `,
        icon: <ExploitDogTwoIcon
            // @ts-ignore
            sx={{
                width: '100%',
                height: 'auto',
                maxHeight: '400px'
            }}
        />
    },
    {
        title: <>Снижение <br/> эксплуатационных <br/> расходов</>,
        description: `
            Сервис включает облачные решения, не требующие капитальных затрат и дополнительного
обслуживающего персонала. Отсутствует необходимость в дополнительной инфраструктуре и программном обеспечении.
        `,
        icon: <ExploitDogThreeIcon
            // @ts-ignore
            sx={{
                width: '100%',
                height: 'auto',
                maxHeight: '400px'
            }}
        />
    },
]

export default function AboutExploitDog() {
    return (
        <Box
            sx={{
                margin: '80px 0',
                backgroundColor: '#F8F7FE',
                padding: '80px 0'
            }}
        >
            <Container>

                <Typography
                    id="products"
                    variant="h2"
                    sx={{
                        fontSize: '42px',
                        fontWeight: "bold",
                        marginBottom: '16px',
                        textAlign: 'center'
                    }}
                >
                    Наши продукты
                </Typography>


                <Typography
                    variant="h3"
                    sx={{
                        fontSize: '36px',
                        fontWeight: "bold",
                        marginTop: '40px',
                        textAlign: 'center',
                        color: '#5f00bc'
                    }}
                >
                    ExploitDog
                </Typography>

                <Typography
                    variant="subtitle1"
                    sx={{
                        textAlign: 'center',
                        margin: '20px auto',
                        maxWidth: '80%',
                        fontSize: '20px'
                    }}
                >
                    Сервис управления уязвимостями предлагающее комплексное решение для обнаружения, оценки и
                    устранения уязвимостей в системе.
                </Typography>

                <Grid
                    container
                    rowSpacing={12}
                    columnSpacing={3}
                    mt={4}
                >
                    {arr.map(({title, description, icon}, index) => (
                        <React.Fragment key={index}>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                sx={{
                                    display: {
                                        md: index % 2 === 0 ? 'block' : 'none',
                                        xs: 'none',
                                    }
                                }}
                            >
                                {icon}
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                sx={{
                                    textAlign: {
                                        md: index % 2 !== 0 ? 'right' : 'left',
                                        xs: 'center'
                                    },
                                    '& br': {
                                        display: {
                                            md: 'block',
                                            xs: 'none'
                                        }
                                    }
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                >
                                    {title}
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        marginTop: '24px',
                                        fontSize: '18px',
                                    }}

                                >
                                    {description}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                sx={{
                                    display: {
                                        md: index % 2 !== 0 ? 'block' : 'none',
                                        xs: 'block',
                                    }
                                }}
                            >
                                {icon}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>

                <Box textAlign="center"
                     sx={{
                         marginTop: '80px'
                     }}
                >
                    <Link
                        to="exploitdog"
                        style={{
                            textDecoration: 'none'
                        }}
                    >
                        <Button
                            size="large"
                            variant="contained"
                        >
                            Подробнее об ExploitDog
                        </Button>
                    </Link>
                </Box>
            </Container>
        </Box>
    )
}
