import React from 'react'
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

type Props = {}

const ExploitDogOneIcon: React.FC<Props> = (props: SvgIconProps) => {
    return (
        <SvgIcon
            width="381" height="412" viewBox="0 0 381 412"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <g>
                <path
                    d="M374.671 231.361H164.554C162.876 231.359 161.267 230.691 160.081 229.505C158.894 228.319 158.227 226.71 158.225 225.032V154.156C158.227 152.478 158.894 150.87 160.081 149.684C161.267 148.497 162.876 147.83 164.554 147.828H374.671C376.349 147.83 377.957 148.497 379.144 149.684C380.33 150.87 380.998 152.478 381 154.156V225.032C380.998 226.71 380.33 228.319 379.144 229.505C377.957 230.691 376.349 231.359 374.671 231.361V231.361Z"
                    fill="#E5E5E5"/>
                <path d="M246.828 166.812H191.135V173.141H246.828V166.812Z" fill="white"/>
                <path
                    d="M320.243 174.406C322.34 174.406 324.04 172.706 324.04 170.609C324.04 168.512 322.34 166.812 320.243 166.812C318.146 166.812 316.446 168.512 316.446 170.609C316.446 172.706 318.146 174.406 320.243 174.406Z"
                    fill="#CE9FFC">
                    <animate attributeName="fill" dur="2s" repeatCount="indefinite" begin="0s"
                             values="#CE9FFC;#00ff07;#CE9FFC" fill="freeze"/>
                </path>
                <path
                    d="M332.9 174.406C334.998 174.406 336.698 172.706 336.698 170.609C336.698 168.512 334.998 166.812 332.9 166.812C330.803 166.812 329.103 168.512 329.103 170.609C329.103 172.706 330.803 174.406 332.9 174.406Z"
                    fill="#CE9FFC"/>
                <path
                    d="M345.558 174.406C347.655 174.406 349.355 172.706 349.355 170.609C349.355 168.512 347.655 166.812 345.558 166.812C343.461 166.812 341.761 168.512 341.761 170.609C341.761 172.706 343.461 174.406 345.558 174.406Z"
                    fill="#CE9FFC"/>
                <animateTransform
                    attributeName="transform"
                    type="translate"
                    // from="0 60 70"
                    // to="360 60 70"
                    values="-20 0; 0 0; -20 0"
                    dur="9s"
                    repeatCount="indefinite"/>
            </g>

            <g>
                <path
                    d="M374.671 321.221H164.554C162.876 321.219 161.267 320.552 160.081 319.366C158.894 318.179 158.227 316.571 158.225 314.893V244.017C158.227 242.339 158.894 240.731 160.081 239.544C161.267 238.358 162.876 237.691 164.554 237.689H374.671C376.349 237.691 377.957 238.358 379.144 239.544C380.33 240.731 380.998 242.339 381 244.017V314.893C380.998 316.571 380.33 318.179 379.144 319.366C377.957 320.552 376.349 321.219 374.671 321.221V321.221Z"
                    fill="#E5E5E5"/>


                <path d="M246.828 256.673H191.135V263.002H246.828V256.673Z" fill="white"/>
                <path
                    d="M320.243 264.267C322.34 264.267 324.04 262.567 324.04 260.47C324.04 258.373 322.34 256.673 320.243 256.673C318.146 256.673 316.446 258.373 316.446 260.47C316.446 262.567 318.146 264.267 320.243 264.267Z"
                    fill="#CE9FFC"/>
                <path
                    d="M332.9 264.267C334.998 264.267 336.698 262.567 336.698 260.47C336.698 258.373 334.998 256.673 332.9 256.673C330.803 256.673 329.103 258.373 329.103 260.47C329.103 262.567 330.803 264.267 332.9 264.267Z"
                    fill="#CE9FFC">
                    <animate attributeName="fill" dur="2s" repeatCount="indefinite" begin="0s"
                             values="#CE9FFC;#ff3232;#CE9FFC" fill="freeze"/>
                </path>
                <path
                    d="M345.558 264.267C347.655 264.267 349.355 262.567 349.355 260.47C349.355 258.373 347.655 256.673 345.558 256.673C343.461 256.673 341.761 258.373 341.761 260.47C341.761 262.567 343.461 264.267 345.558 264.267Z"
                    fill="#CE9FFC"/>
                <animateTransform
                    attributeName="transform"
                    type="translate"
                    // from="0 60 70"
                    // to="360 60 70"
                    values="0 0; -20 0; 0 0"
                    dur="9s"
                    repeatCount="indefinite"/>
            </g>

            <g>
                <path
                    d="M374.671 411.082H164.554C162.876 411.08 161.267 410.413 160.081 409.227C158.894 408.04 158.227 406.432 158.225 404.754V333.878C158.227 332.2 158.894 330.592 160.081 329.405C161.267 328.219 162.876 327.552 164.554 327.55H374.671C376.349 327.551 377.957 328.219 379.144 329.405C380.33 330.592 380.998 332.2 381 333.878V404.754C380.998 406.432 380.33 408.04 379.144 409.227C377.957 410.413 376.349 411.08 374.671 411.082Z"
                    fill="#E5E5E5"/>


                <path d="M246.828 346.534H191.135V352.863H246.828V346.534Z" fill="white"/>
                <path
                    d="M320.243 354.128C322.34 354.128 324.04 352.428 324.04 350.331C324.04 348.234 322.34 346.534 320.243 346.534C318.146 346.534 316.446 348.234 316.446 350.331C316.446 352.428 318.146 354.128 320.243 354.128Z"
                    fill="#CE9FFC"/>
                <path
                    d="M332.9 354.128C334.998 354.128 336.698 352.428 336.698 350.331C336.698 348.234 334.998 346.534 332.9 346.534C330.803 346.534 329.103 348.234 329.103 350.331C329.103 352.428 330.803 354.128 332.9 354.128Z"
                    fill="#CE9FFC">
                    <animate attributeName="fill" dur="2s" repeatCount="indefinite" begin="0s"
                             values="#CE9FFC;#00ff07;#CE9FFC" fill="freeze"/>
                </path>
                <path
                    d="M345.558 354.128C347.655 354.128 349.355 352.428 349.355 350.331C349.355 348.234 347.655 346.534 345.558 346.534C343.461 346.534 341.761 348.234 341.761 350.331C341.761 352.428 343.461 354.128 345.558 354.128Z"
                    fill="#CE9FFC">
                    <animate attributeName="fill" dur="2s" repeatCount="indefinite" begin="0s"
                             values="#CE9FFC;#ff3232;#CE9FFC" fill="freeze"/>
                </path>

                <animateTransform
                    attributeName="transform"
                    type="translate"
                    // from="0 60 70"
                    // to="360 60 70"
                    values="-20 0; 0; 0; -20 0"
                    dur="9s"
                    repeatCount="indefinite"/>
            </g>
            <g>
                <path
                    d="M122.531 258.259C118.529 258.245 114.545 257.73 110.671 256.725L109.908 256.513L109.199 256.159C83.5936 243.322 61.9915 226.366 44.9929 205.762C30.9215 188.606 19.9509 169.127 12.5743 148.202C3.75297 123.149 -0.490945 96.7138 0.0451256 70.1587C0.0559245 69.6013 0.0651082 69.1709 0.0651082 68.8744C0.0651082 55.9658 7.23106 44.6395 18.3211 40.019C26.8089 36.4828 103.874 4.83204 109.442 2.54518C119.928 -2.70865 131.116 1.67655 132.906 2.44333C136.921 4.08497 208.163 33.221 223.559 40.5512C239.425 48.1059 243.658 61.6777 243.658 68.5059C243.658 99.4193 238.304 128.31 227.744 154.376C219.216 175.475 207.128 194.953 192.009 211.962C162.837 244.788 133.657 256.425 133.375 256.527C129.888 257.727 126.219 258.313 122.531 258.259V258.259ZM115.669 241.263C118.199 241.83 124.023 242.68 127.82 241.296C132.642 239.537 157.065 226.874 179.888 201.193C211.42 165.711 227.42 121.107 227.444 68.62C227.387 67.5567 226.632 59.9725 216.587 55.19C201.491 48.0016 127.441 17.7255 126.694 17.4204L126.49 17.3336C124.938 16.6834 119.999 15.3138 116.595 17.0976L115.913 17.4153C115.088 17.7543 33.2823 51.3504 24.5577 54.9852C18.4546 57.528 16.2801 63.8272 16.2801 68.8744C16.2801 69.2433 16.2705 69.7798 16.257 70.4733C15.5598 106.387 23.8712 194.759 115.669 241.263Z"
                    fill="#3F3D56"/>
                <path
                    d="M112.833 9.91649C112.833 9.91649 30.2839 43.8174 21.4394 47.5022C12.5948 51.1871 8.17254 60.0308 8.17254 68.8746C8.17254 77.7183 1.5391 193.117 112.833 248.912C112.833 248.912 122.934 251.706 130.597 248.912C138.261 246.117 235.551 198.951 235.551 68.506C235.551 68.506 235.551 55.2405 220.073 47.8707C204.595 40.501 129.762 9.91649 129.762 9.91649C129.762 9.91649 120.572 5.86311 112.833 9.91649Z"
                    fill="#CE9FFC"/>
                <path opacity="0.3"
                      d="M121.677 36.4475V217.297C121.677 217.297 204.964 177.21 204.227 71.8224L121.677 36.4475Z"
                      fill="black">
                    <animate attributeName="opacity" dur="4s" repeatCount="indefinite" begin="0s" values="0.3;0.1;0.3"
                             fill="freeze"/>
                </path>
                <animateTransform
                    attributeName="transform"
                    type="translate"
                    // from="0 60 70"
                    // to="360 60 70"
                    values="20 20; 60 60; 20 20"
                    dur="7s"
                    repeatCount="indefinite"/>
            </g>
        </SvgIcon>
    )
}

export default ExploitDogOneIcon
