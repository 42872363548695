import React from "react";
import {Box, Grid, Typography, Button} from "@mui/material";
import {WorldIcon} from "../Icons";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {Typewriter} from "react-simple-typewriter";


export default function Banner() {
    return (
        <Grid
            container
            mt={2}
            p={4}
            sx={{
                borderRadius: {
                    lg: '32px 32px 144px 32px',
                    xs: '32px'
                },
                background: 'linear-gradient(135deg, #CE9FFC 0%, #7367F0 100%)',
                //padding: '48px '
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
            }}
        >
            <Grid item xs={12} md={6}>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: {
                            lg: '56px',
                            sm: '40px',
                            xs: '36px',
                        },
                        fontWeight: 'bold',
                        color: 'white'
                    }}
                >
                    Навигатор
                    <br/>

                    <Typewriter
                        words={['инновационных', 'надежных', 'современных']}
                        loop={false}
                        cursor
                        typeSpeed={100}
                        deleteSpeed={50}
                        delaySpeed={2500}
                    />
                    <br/>
                    решений
                </Typography>

                <Typography
                    sx={{
                        margin: '32px 0',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >
                    Свежий взгляд
                    <br/>
                    на решения Информационной Безопасности.
                </Typography>

                <AnchorLink
                    to="/#call"
                >
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            textTransform: 'none'
                        }}
                    >
                        Связаться с нами
                    </Button>
                </AnchorLink>
            </Grid>
            <Grid
                item
                xs
                sx={{
                    display: {
                        md: 'flex',
                        xs: 'none',
                    }
                }}
            >
                <WorldIcon
                    // @ts-ignore
                    sx={{
                        width: '100%',
                        height: 'auto'
                    }}
                />
            </Grid>
        </Grid>
    )
}
