import React from 'react'
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import {SxProps} from "@mui/system/styleFunctionSx";

type Props = {}

const CompanyTeamIcon: React.FC<Props> = (props: SvgIconProps) => {
    return (
        <SvgIcon
            width="252" height="218" viewBox="0 0 252 218" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <g clipPath="url(#clip0_4_112)">
                <path
                    d="M198.996 18.0024C198.713 17.8136 198.427 17.6346 198.143 17.4524C189.957 12.2182 181.182 7.96606 172 4.78371L169.871 34.1433H156.375V0.497896C155.525 0.322168 154.669 0.156203 153.813 0V34.1433H137.775C128.945 34.1433 120.477 30.6369 114.234 24.3956C107.99 18.1542 104.483 9.68905 104.483 0.862405V0.862372C103.624 1.04786 102.771 1.24637 101.918 1.4579V34.1433H88.8545L86.8265 6.14723C77.391 9.76472 68.4333 14.5219 60.1528 20.3129C60.0096 20.4105 59.8664 20.5114 59.7264 20.609C59.007 21.1134 58.2973 21.6276 57.591 22.1483V134.155H96.0909L95.4106 124.734L91.667 73.0085H167.056L163.312 124.734L162.632 134.155H200.705V19.1349C200.139 18.7509 199.569 18.3734 198.996 18.0024ZM101.918 64.4662H91.0485L89.04 36.7076H101.918V64.4662ZM153.813 64.4662H135.558V63.9162C135.558 63.6518 135.506 63.39 135.404 63.1458C135.303 62.9016 135.155 62.6798 134.967 62.493C134.78 62.3062 134.558 62.1581 134.314 62.0572C134.069 61.9564 133.807 61.9047 133.543 61.9051H125.606C125.342 61.905 125.081 61.9569 124.836 62.058C124.592 62.159 124.37 62.3071 124.184 62.4939C123.997 62.6807 123.849 62.9024 123.748 63.1465C123.646 63.3905 123.595 63.6521 123.595 63.9162V64.4662H104.483V36.7076H153.813V64.4662ZM156.375 64.4662V36.7076H169.686L167.677 64.4662H156.375Z"
                    fill="#F2F2F2"/>
                <path
                    d="M240.808 203.568L241.062 197.848C243.715 196.431 246.674 195.682 249.681 195.667C245.542 199.05 246.059 205.572 243.253 210.122C242.366 211.534 241.174 212.729 239.763 213.618C238.352 214.507 236.759 215.067 235.102 215.257L231.638 217.377C231.162 214.728 231.267 212.007 231.945 209.403C232.623 206.798 233.857 204.371 235.563 202.289C236.532 201.131 237.643 200.099 238.87 199.218C239.7 201.405 240.808 203.568 240.808 203.568Z"
                    fill="#F2F2F2"/>
                <path
                    d="M54.9485 197.3L46.5889 194.77C45.4925 190.317 45.5 185.664 46.6107 181.215C50.0533 188.605 59.8896 190.297 65.5586 196.157C67.3127 197.998 68.6298 200.21 69.4124 202.629C70.195 205.049 70.423 207.613 70.0795 210.132L71.9093 216.049C67.814 215.753 63.8315 214.574 60.2362 212.592C56.6408 210.609 53.5181 207.871 51.0832 204.566C49.7356 202.699 48.6285 200.669 47.7883 198.525C51.3338 198.123 54.9485 197.3 54.9485 197.3Z"
                    fill="#F2F2F2"/>
                <path
                    d="M234.303 216.442C234.302 216.615 234.233 216.781 234.111 216.904C234.05 216.964 233.979 217.011 233.9 217.044C233.821 217.076 233.737 217.093 233.652 217.093H231.035C230.949 217.093 230.864 217.076 230.785 217.044C230.706 217.011 230.634 216.963 230.574 216.903C230.513 216.842 230.465 216.77 230.432 216.691C230.4 216.612 230.383 216.527 230.383 216.442V137.318C230.383 135.574 229.798 134.159 229.078 134.155H32.9519C32.2292 134.159 31.6465 135.574 31.6433 137.318V215.957C31.6427 216.119 31.5822 216.274 31.4733 216.394C31.3645 216.514 31.2151 216.589 31.0541 216.604C30.5267 216.656 29.9994 216.702 29.4753 216.744C29.4622 216.744 29.4492 216.748 29.4362 216.748C29.1497 216.774 28.8633 216.793 28.5768 216.816H28.5736C28.4238 216.546 28.2806 216.269 28.1341 215.993C28.0871 215.899 28.0626 215.795 28.0625 215.69V133.215C28.0625 132.733 28.082 132.248 28.1146 131.77C28.161 131.113 28.2425 130.459 28.3587 129.811C28.9089 126.788 30.1524 124.741 31.5651 124.734H230.462C230.668 124.736 230.872 124.779 231.061 124.861C231.338 124.984 231.589 125.16 231.8 125.379C232.085 125.675 232.329 126.009 232.525 126.371C233.222 127.598 233.72 129.427 233.896 131.555C233.896 131.575 233.899 131.594 233.899 131.614C233.945 132.141 233.968 132.678 233.964 133.215L234.01 140.189L234.039 144.348L234.127 157.397L234.16 162.031L234.303 216.442Z"
                    fill="#E6E6E6"/>
                <path
                    d="M251.612 217.9L161.849 218C161.746 218 161.648 217.959 161.575 217.886C161.503 217.813 161.462 217.715 161.462 217.612C161.462 217.51 161.503 217.412 161.575 217.339C161.648 217.266 161.746 217.225 161.849 217.225L251.612 217.125C251.715 217.125 251.813 217.166 251.886 217.239C251.958 217.312 251.999 217.41 251.999 217.512C251.999 217.615 251.958 217.713 251.886 217.786C251.813 217.859 251.715 217.9 251.612 217.9Z"
                    fill="#CACACA"/>
                <path
                    d="M140.194 101.617H124.296C124.071 101.617 123.855 101.707 123.696 101.866C123.537 102.025 123.448 102.241 123.449 102.466V127.572H141.043V102.466C141.043 102.354 141.021 102.244 140.978 102.141C140.936 102.038 140.873 101.944 140.794 101.865C140.715 101.786 140.622 101.724 140.519 101.681C140.416 101.639 140.305 101.617 140.194 101.617ZM132.298 115.882C131.801 115.88 131.325 115.681 130.973 115.33C130.622 114.978 130.423 114.502 130.421 114.005V111.109C130.421 110.612 130.619 110.134 130.971 109.782C131.323 109.43 131.8 109.233 132.298 109.233C132.796 109.233 133.274 109.43 133.626 109.782C133.978 110.134 134.175 110.612 134.175 111.109V114.005C134.173 114.502 133.975 114.978 133.623 115.33C133.271 115.681 132.795 115.88 132.298 115.882Z"
                    fill="#2F2E41"/>
                <path
                    d="M166.52 62.4355H98.5613C97.9775 62.4365 97.4178 62.6688 97.0049 63.0815C96.5921 63.4942 96.3597 64.0537 96.3588 64.6374V110.481C96.3593 111.065 96.5916 111.625 97.0045 112.038C97.4175 112.45 97.9774 112.683 98.5613 112.683H166.52C167.104 112.683 167.663 112.45 168.076 112.038C168.489 111.625 168.722 111.065 168.722 110.481V64.6374C168.721 64.0537 168.489 63.4942 168.076 63.0815C167.663 62.6688 167.103 62.4365 166.52 62.4355Z"
                    fill="#3F3D56"/>
                <path
                    d="M165.951 64.0255H99.128C98.8153 64.0262 98.5158 64.1508 98.2949 64.372C98.0741 64.5932 97.9501 64.893 97.9501 65.2055V109.913C97.9506 110.225 98.0749 110.524 98.2956 110.745C98.5164 110.966 98.8157 111.09 99.128 111.091H165.951C166.263 111.09 166.563 110.966 166.783 110.745C167.004 110.524 167.129 110.225 167.129 109.913V65.2055C167.129 64.893 167.005 64.5932 166.784 64.372C166.563 64.1508 166.264 64.0262 165.951 64.0255Z"
                    fill="white"/>
                <path
                    d="M123.394 126.769V129.191C123.394 129.317 123.444 129.437 123.533 129.526C123.622 129.615 123.742 129.665 123.868 129.665H140.624C140.749 129.664 140.869 129.614 140.958 129.525C141.047 129.437 141.097 129.317 141.097 129.191V126.769H123.394Z"
                    fill="#3F3D56"/>
                <path
                    d="M152.936 130.508H113.18C113.071 130.508 112.964 130.483 112.866 130.437C112.767 130.39 112.681 130.322 112.612 130.237C112.544 130.153 112.495 130.054 112.469 129.948C112.444 129.843 112.442 129.732 112.464 129.626L113.081 126.697C113.116 126.533 113.207 126.386 113.337 126.281C113.467 126.175 113.629 126.117 113.797 126.117H152.32C152.487 126.117 152.65 126.175 152.78 126.281C152.91 126.386 153 126.533 153.035 126.697L153.652 129.626C153.674 129.732 153.673 129.843 153.647 129.948C153.622 130.054 153.573 130.153 153.504 130.237C153.436 130.322 153.349 130.39 153.251 130.437C153.153 130.483 153.045 130.508 152.936 130.508Z"
                    fill="#2F2E41"/>
                <path
                    d="M115.145 126.644H114.238C114.15 126.644 114.079 126.715 114.079 126.803V127.22C114.079 127.307 114.15 127.378 114.238 127.378H115.145C115.233 127.378 115.304 127.307 115.304 127.22V126.803C115.304 126.715 115.233 126.644 115.145 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M117.594 126.644H116.687C116.599 126.644 116.528 126.715 116.528 126.803V127.22C116.528 127.307 116.599 127.378 116.687 127.378H117.594C117.681 127.378 117.753 127.307 117.753 127.22V126.803C117.753 126.715 117.681 126.644 117.594 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M120.043 126.644H119.136C119.048 126.644 118.977 126.715 118.977 126.803V127.22C118.977 127.307 119.048 127.378 119.136 127.378H120.043C120.13 127.378 120.201 127.307 120.201 127.22V126.803C120.201 126.715 120.13 126.644 120.043 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M122.491 126.644H121.585C121.497 126.644 121.426 126.715 121.426 126.803V127.22C121.426 127.307 121.497 127.378 121.585 127.378H122.491C122.579 127.378 122.65 127.307 122.65 127.22V126.803C122.65 126.715 122.579 126.644 122.491 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M124.94 126.644H124.034C123.946 126.644 123.875 126.715 123.875 126.803V127.22C123.875 127.307 123.946 127.378 124.034 127.378H124.94C125.028 127.378 125.099 127.307 125.099 127.22V126.803C125.099 126.715 125.028 126.644 124.94 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M127.389 126.644H126.483C126.395 126.644 126.324 126.715 126.324 126.803V127.22C126.324 127.307 126.395 127.378 126.483 127.378H127.389C127.477 127.378 127.548 127.307 127.548 127.22V126.803C127.548 126.715 127.477 126.644 127.389 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M129.838 126.644H128.932C128.844 126.644 128.773 126.715 128.773 126.803V127.22C128.773 127.307 128.844 127.378 128.932 127.378H129.838C129.926 127.378 129.997 127.307 129.997 127.22V126.803C129.997 126.715 129.926 126.644 129.838 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M132.287 126.644H131.38C131.293 126.644 131.222 126.715 131.222 126.803V127.22C131.222 127.307 131.293 127.378 131.38 127.378H132.287C132.375 127.378 132.446 127.307 132.446 127.22V126.803C132.446 126.715 132.375 126.644 132.287 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M134.736 126.644H133.829C133.742 126.644 133.67 126.715 133.67 126.803V127.22C133.67 127.307 133.742 127.378 133.829 127.378H134.736C134.824 127.378 134.895 127.307 134.895 127.22V126.803C134.895 126.715 134.824 126.644 134.736 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M137.185 126.644H136.278C136.191 126.644 136.119 126.715 136.119 126.803V127.22C136.119 127.307 136.191 127.378 136.278 127.378H137.185C137.273 127.378 137.344 127.307 137.344 127.22V126.803C137.344 126.715 137.273 126.644 137.185 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M139.634 126.644H138.727C138.639 126.644 138.568 126.715 138.568 126.803V127.22C138.568 127.307 138.639 127.378 138.727 127.378H139.634C139.722 127.378 139.793 127.307 139.793 127.22V126.803C139.793 126.715 139.722 126.644 139.634 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M142.083 126.644H141.176C141.088 126.644 141.017 126.715 141.017 126.803V127.22C141.017 127.307 141.088 127.378 141.176 127.378H142.083C142.17 127.378 142.242 127.307 142.242 127.22V126.803C142.242 126.715 142.17 126.644 142.083 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M144.532 126.644H143.625C143.537 126.644 143.466 126.715 143.466 126.803V127.22C143.466 127.307 143.537 127.378 143.625 127.378H144.532C144.619 127.378 144.69 127.307 144.69 127.22V126.803C144.69 126.715 144.619 126.644 144.532 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M146.98 126.644H146.074C145.986 126.644 145.915 126.715 145.915 126.803V127.22C145.915 127.307 145.986 127.378 146.074 127.378H146.98C147.068 127.378 147.139 127.307 147.139 127.22V126.803C147.139 126.715 147.068 126.644 146.98 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M149.429 126.644H148.523C148.435 126.644 148.364 126.715 148.364 126.803V127.22C148.364 127.307 148.435 127.378 148.523 127.378H149.429C149.517 127.378 149.588 127.307 149.588 127.22V126.803C149.588 126.715 149.517 126.644 149.429 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M151.878 126.644H150.972C150.884 126.644 150.813 126.715 150.813 126.803V127.22C150.813 127.307 150.884 127.378 150.972 127.378H151.878C151.966 127.378 152.037 127.307 152.037 127.22V126.803C152.037 126.715 151.966 126.644 151.878 126.644Z"
                    fill="#3F3D56"/>
                <path
                    d="M115.116 127.868H114.209C114.121 127.868 114.05 127.939 114.05 128.027V128.444C114.05 128.531 114.121 128.602 114.209 128.602H115.116C115.204 128.602 115.275 128.531 115.275 128.444V128.027C115.275 127.939 115.204 127.868 115.116 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M117.565 127.868H116.658C116.57 127.868 116.499 127.939 116.499 128.027V128.444C116.499 128.531 116.57 128.602 116.658 128.602H117.565C117.652 128.602 117.724 128.531 117.724 128.444V128.027C117.724 127.939 117.652 127.868 117.565 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M120.014 127.868H119.107C119.019 127.868 118.948 127.939 118.948 128.027V128.444C118.948 128.531 119.019 128.602 119.107 128.602H120.014C120.101 128.602 120.173 128.531 120.173 128.444V128.027C120.173 127.939 120.101 127.868 120.014 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M122.463 127.868H121.556C121.468 127.868 121.397 127.939 121.397 128.027V128.444C121.397 128.531 121.468 128.602 121.556 128.602H122.463C122.55 128.602 122.621 128.531 122.621 128.444V128.027C122.621 127.939 122.55 127.868 122.463 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M124.911 127.868H124.005C123.917 127.868 123.846 127.939 123.846 128.027V128.444C123.846 128.531 123.917 128.602 124.005 128.602H124.911C124.999 128.602 125.07 128.531 125.07 128.444V128.027C125.07 127.939 124.999 127.868 124.911 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M127.36 127.868H126.454C126.366 127.868 126.295 127.939 126.295 128.027V128.444C126.295 128.531 126.366 128.602 126.454 128.602H127.36C127.448 128.602 127.519 128.531 127.519 128.444V128.027C127.519 127.939 127.448 127.868 127.36 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M129.809 127.868H128.903C128.815 127.868 128.744 127.939 128.744 128.027V128.444C128.744 128.531 128.815 128.602 128.903 128.602H129.809C129.897 128.602 129.968 128.531 129.968 128.444V128.027C129.968 127.939 129.897 127.868 129.809 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M132.258 127.868H131.352C131.264 127.868 131.193 127.939 131.193 128.027V128.444C131.193 128.531 131.264 128.602 131.352 128.602H132.258C132.346 128.602 132.417 128.531 132.417 128.444V128.027C132.417 127.939 132.346 127.868 132.258 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M134.707 127.868H133.8C133.713 127.868 133.642 127.939 133.642 128.027V128.444C133.642 128.531 133.713 128.602 133.8 128.602H134.707C134.795 128.602 134.866 128.531 134.866 128.444V128.027C134.866 127.939 134.795 127.868 134.707 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M137.156 127.868H136.249C136.162 127.868 136.09 127.939 136.09 128.027V128.444C136.09 128.531 136.162 128.602 136.249 128.602H137.156C137.244 128.602 137.315 128.531 137.315 128.444V128.027C137.315 127.939 137.244 127.868 137.156 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M139.605 127.868H138.698C138.61 127.868 138.539 127.939 138.539 128.027V128.444C138.539 128.531 138.61 128.602 138.698 128.602H139.605C139.693 128.602 139.764 128.531 139.764 128.444V128.027C139.764 127.939 139.693 127.868 139.605 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M142.054 127.868H141.147C141.059 127.868 140.988 127.939 140.988 128.027V128.444C140.988 128.531 141.059 128.602 141.147 128.602H142.054C142.142 128.602 142.213 128.531 142.213 128.444V128.027C142.213 127.939 142.142 127.868 142.054 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M144.503 127.868H143.596C143.508 127.868 143.437 127.939 143.437 128.027V128.444C143.437 128.531 143.508 128.602 143.596 128.602H144.503C144.59 128.602 144.662 128.531 144.662 128.444V128.027C144.662 127.939 144.59 127.868 144.503 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M146.952 127.868H146.045C145.957 127.868 145.886 127.939 145.886 128.027V128.444C145.886 128.531 145.957 128.602 146.045 128.602H146.952C147.039 128.602 147.11 128.531 147.11 128.444V128.027C147.11 127.939 147.039 127.868 146.952 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M149.4 127.868H148.494C148.406 127.868 148.335 127.939 148.335 128.027V128.444C148.335 128.531 148.406 128.602 148.494 128.602H149.4C149.488 128.602 149.559 128.531 149.559 128.444V128.027C149.559 127.939 149.488 127.868 149.4 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M151.849 127.868H150.943C150.855 127.868 150.784 127.939 150.784 128.027V128.444C150.784 128.531 150.855 128.602 150.943 128.602H151.849C151.937 128.602 152.008 128.531 152.008 128.444V128.027C152.008 127.939 151.937 127.868 151.849 127.868Z"
                    fill="#3F3D56"/>
                <path
                    d="M135.442 129.337H125.964C125.876 129.337 125.805 129.408 125.805 129.496V129.912C125.805 130 125.876 130.071 125.964 130.071H135.442C135.529 130.071 135.601 130 135.601 129.912V129.496C135.601 129.408 135.529 129.337 135.442 129.337Z"
                    fill="#3F3D56"/>
                <path
                    d="M162.593 70.1856H100.067C100.002 70.1843 99.9401 70.1575 99.8945 70.111C99.849 70.0645 99.8235 70.0021 99.8235 69.937C99.8235 69.872 99.849 69.8095 99.8945 69.7631C99.9401 69.7166 100.002 69.6898 100.067 69.6885H162.593C162.658 69.6898 162.72 69.7166 162.766 69.7631C162.811 69.8095 162.837 69.872 162.837 69.937C162.837 70.0021 162.811 70.0645 162.766 70.111C162.72 70.1575 162.658 70.1843 162.593 70.1856Z"
                    fill="#CACACA"/>
                <path
                    d="M104.323 68.4193C105.128 68.4193 105.78 67.7526 105.78 66.9301C105.78 66.1077 105.128 65.4409 104.323 65.4409C103.518 65.4409 102.866 66.1077 102.866 66.9301C102.866 67.7526 103.518 68.4193 104.323 68.4193Z"
                    fill="#3F3D56"/>
                <path
                    d="M109.357 68.4193C110.162 68.4193 110.814 67.7526 110.814 66.9301C110.814 66.1077 110.162 65.4409 109.357 65.4409C108.552 65.4409 107.9 66.1077 107.9 66.9301C107.9 67.7526 108.552 68.4193 109.357 68.4193Z"
                    fill="#3F3D56"/>
                <path
                    d="M114.391 68.4193C115.196 68.4193 115.848 67.7526 115.848 66.9301C115.848 66.1077 115.196 65.4409 114.391 65.4409C113.586 65.4409 112.934 66.1077 112.934 66.9301C112.934 67.7526 113.586 68.4193 114.391 68.4193Z"
                    fill="#3F3D56"/>
                <path
                    d="M159.562 65.8101H155.986C155.916 65.8115 155.848 65.8406 155.798 65.8912C155.749 65.9418 155.721 66.0099 155.721 66.0807C155.721 66.1516 155.749 66.2196 155.798 66.2702C155.848 66.3209 155.916 66.35 155.986 66.3514H159.562C159.633 66.35 159.701 66.3209 159.75 66.2702C159.8 66.2196 159.828 66.1516 159.828 66.0807C159.828 66.0099 159.8 65.9418 159.75 65.8912C159.701 65.8406 159.633 65.8115 159.562 65.8101Z"
                    fill="#3F3D56"/>
                <path
                    d="M159.562 66.826H155.986C155.916 66.8275 155.848 66.8566 155.798 66.9072C155.749 66.9578 155.721 67.0259 155.721 67.0967C155.721 67.1676 155.749 67.2356 155.798 67.2862C155.848 67.3368 155.916 67.366 155.986 67.3674H159.562C159.633 67.366 159.701 67.3368 159.75 67.2862C159.8 67.2356 159.828 67.1676 159.828 67.0967C159.828 67.0259 159.8 66.9578 159.75 66.9072C159.701 66.8566 159.633 66.8275 159.562 66.826Z"
                    fill="#3F3D56"/>
                <path
                    d="M159.562 67.8407H155.986C155.916 67.8421 155.848 67.8712 155.798 67.9219C155.749 67.9725 155.721 68.0405 155.721 68.1114C155.721 68.1822 155.749 68.2503 155.798 68.3009C155.848 68.3515 155.916 68.3806 155.986 68.382H159.562C159.633 68.3806 159.701 68.3515 159.75 68.3009C159.8 68.2503 159.828 68.1822 159.828 68.1114C159.828 68.0405 159.8 67.9725 159.75 67.9219C159.701 67.8712 159.633 67.8421 159.562 67.8407Z"
                    fill="#3F3D56"/>
                <path
                    d="M136.325 84.7073H128.512C127.925 84.7073 127.449 85.1829 127.449 85.7696V85.7696C127.449 86.3562 127.925 86.8318 128.512 86.8318H136.325C136.912 86.8318 137.387 86.3562 137.387 85.7696V85.7696C137.387 85.1829 136.912 84.7073 136.325 84.7073Z"
                    fill="#CE9FFC"/>
                <path
                    d="M141.372 88.9563H123.465C122.878 88.9563 122.402 89.4319 122.402 90.0185V90.0185C122.402 90.6052 122.878 91.0808 123.465 91.0808H141.372C141.959 91.0808 142.435 90.6052 142.435 90.0185C142.435 89.4319 141.959 88.9563 141.372 88.9563Z"
                    fill="#E6E6E6"/>
                <path
                    d="M141.372 93.2053H123.465C122.878 93.2053 122.402 93.6808 122.402 94.2675C122.402 94.8541 122.878 95.3297 123.465 95.3297H141.372C141.959 95.3297 142.435 94.8541 142.435 94.2675C142.435 93.6808 141.959 93.2053 141.372 93.2053Z"
                    fill="#E6E6E6"/>
                <path
                    d="M100.678 133.703H81.146C80.4553 133.703 79.793 133.977 79.3046 134.466C78.8162 134.954 78.5418 135.616 78.5418 136.306C78.5418 136.997 78.8162 137.659 79.3046 138.147C79.793 138.636 80.4553 138.91 81.146 138.91H88.3076V159.086H93.516V138.91H100.678C101.368 138.91 102.031 138.636 102.519 138.147C103.007 137.659 103.282 136.997 103.282 136.306C103.282 135.616 103.007 134.954 102.519 134.466C102.031 133.977 101.368 133.703 100.678 133.703Z"
                    fill="#3F3D56"/>
                <path
                    d="M113.569 170.26H61.3274C56.2629 170.241 51.374 168.402 47.5539 165.078C43.7338 161.754 41.2375 157.167 40.5209 152.155L38.6717 145.355C34.8442 131.264 29.0142 117.795 21.3598 105.36C17.8612 99.6797 16.0606 94.1816 20.1505 88.8882C20.1853 88.8431 20.2196 88.7987 20.2532 88.7549C23.5336 84.4865 39.8611 83.5626 45.1838 83.3828C45.3941 83.3757 45.6052 83.3721 45.8171 83.3722H78.3107C78.5228 83.3722 78.7338 83.3757 78.9438 83.3828C81.8132 83.4665 84.6064 84.325 87.027 85.8674C89.4477 87.4098 91.4055 89.5786 92.6928 92.1435C94.0664 94.7622 94.7053 97.7038 94.5416 100.656C94.3779 103.609 93.4178 106.462 91.7631 108.912C80.3491 126.053 76.8544 136.8 80.4322 143.76C83.893 150.492 94.5015 153.935 113.818 154.592C119.009 154.769 121.391 158.81 121.408 162.489C121.424 166.073 119.133 170.031 114.066 170.25C113.901 170.256 113.736 170.26 113.569 170.26Z"
                    fill="#3F3D56"/>
                <path
                    d="M70.2021 216.633H89.7336C90.4243 216.633 91.0867 216.358 91.5751 215.87C92.0635 215.382 92.3378 214.72 92.3378 214.029C92.3378 213.339 92.0635 212.677 91.5751 212.188C91.0867 211.7 90.4243 211.426 89.7336 211.426H82.5721V167.494H77.3636V211.426H70.2021C69.5114 211.426 68.849 211.7 68.3606 212.188C67.8722 212.677 67.5979 213.339 67.5979 214.029C67.5979 214.72 67.8722 215.382 68.3606 215.87C68.849 216.358 69.5114 216.633 70.2021 216.633Z"
                    fill="#3F3D56"/>
                <path
                    d="M68.6422 85.0029L69.0516 87.4901C69.2453 88.6961 69.9467 97.2156 69.4271 98.232C69.3813 96.3343 65.8463 93.3506 63.5558 94.0319L59.9613 94.8303L57.7303 95.3265L55.9145 95.7245L50.638 96.8769C50.6288 96.8157 50.6221 96.7506 50.6129 96.6894C50.6004 96.6122 50.588 96.5351 50.5723 96.4585C50.4741 95.9639 50.2949 95.4887 50.0419 95.0524C50.0151 95.6369 49.8425 96.2055 49.5398 96.7063C49.5315 96.7193 49.5243 96.733 49.5183 96.7472C49.4246 96.8989 49.3211 97.0443 49.2087 97.1826C49.2035 97.1862 49.1987 97.1905 49.1947 97.1954C49.1947 97.1954 48.1105 79.9187 48.2241 79.7593C48.3072 79.646 48.391 79.5358 48.4786 79.4282C48.5656 79.3174 48.65 79.2105 48.7408 79.1022C48.7525 79.0919 48.7623 79.0798 48.77 79.0663C49.0244 78.7654 49.2937 78.4775 49.577 78.2037C49.6603 78.1236 49.7467 78.0428 49.8332 77.962C51.1532 76.741 52.7304 75.8315 54.4486 75.3006C54.6424 75.241 54.8439 75.1865 55.0486 75.1313C55.2508 75.08 55.4568 75.0312 55.6641 74.9888L55.7342 74.9745C55.8331 74.9543 55.9358 74.9366 56.0353 74.9195C56.1603 74.8973 56.286 74.8782 56.4092 74.863C58.0078 74.6328 59.6363 74.7243 61.1991 75.1321C61.2023 75.1315 61.203 75.1346 61.2062 75.134C62.5107 75.4748 63.7506 76.0268 64.8766 76.7681C66.8244 78.0538 67.5826 80.1508 68.121 82.5053C68.3097 83.3137 68.4686 84.1548 68.6422 85.0029Z"
                    fill="#2F2E41"/>
                <path d="M87.88 198.921L91.2447 200.749L100.152 188.437L95.1855 185.741L87.88 198.921Z" fill="#FFB6B6"/>
                <path
                    d="M89.4996 194.535L95.6078 196.447L94.6356 199.005L98.824 205.082C99.031 205.382 99.1365 205.74 99.1252 206.105C99.1139 206.469 98.9865 206.82 98.7614 207.107C98.5364 207.394 98.2255 207.601 97.8742 207.699C97.5229 207.796 97.1496 207.779 96.8087 207.65L89.698 204.949L89.434 201.953L87.9938 204.302L85.3128 203.284L89.4996 194.535Z"
                    fill="#2F2E41"/>
                <path
                    d="M106.938 143.856C106.401 143.987 105.842 143.998 105.3 143.89C104.758 143.781 104.247 143.555 103.802 143.227C103.357 142.9 102.989 142.478 102.724 141.993C102.46 141.508 102.305 140.971 102.271 140.419L73.5717 130.098L78.8386 125.516L106.078 136.358C107.008 136.362 107.905 136.707 108.599 137.327C109.292 137.948 109.733 138.801 109.84 139.725C109.946 140.649 109.709 141.58 109.174 142.342C108.64 143.103 107.844 143.642 106.938 143.856Z"
                    fill="#FFB6B6"/>
                <path
                    d="M65.5545 104.529C65.5545 104.529 60.7209 104.635 60.2912 110.152C59.8614 115.668 70.4082 129.313 72.0422 131.563C73.6763 133.813 91.8267 136.583 91.8267 136.583L97.9967 132.105C91.5135 127.906 85.5967 124.686 81.6259 124.83L65.5545 104.529Z"
                    fill="#CE9FFC"/>
                <path
                    d="M59.6264 128.87L60.8042 133.929C60.8042 133.929 60.8158 138.594 61.6422 139.454C62.4628 140.31 80.5082 138.591 80.5082 138.591C80.5082 138.591 78.5949 131.074 78.6204 125.386L59.6264 128.87Z"
                    fill="#FFB6B6"/>
                <path
                    d="M97.7479 193.581L91.2373 190.001C91.1135 181.532 96.2091 170.071 102.253 158.067C88.7362 160.391 75.9843 161.135 64.9545 158.324C63.1437 157.846 61.5938 156.675 60.6398 155.064C59.6857 153.452 59.4041 151.531 59.8558 149.714C59.8988 149.535 59.9484 149.359 60.0048 149.184C61.3248 145.158 58.3649 142.634 61.0873 138.206C68.2477 135.761 75.0498 135.071 81.2699 137.23L85.1673 145.844L104.485 143.345C105.418 143.233 106.364 143.308 107.269 143.563C108.173 143.819 109.018 144.251 109.755 144.835C110.491 145.418 111.105 146.142 111.561 146.964C112.016 147.786 112.305 148.69 112.409 149.624C112.435 149.833 112.452 150.044 112.46 150.255C108.554 168.561 104.174 185.032 97.7479 193.581Z"
                    fill="#2F2E41"/>
                <path
                    d="M60.521 93.9103C64.89 93.0166 67.7071 88.7514 66.8131 84.3838C65.9191 80.0162 61.6526 77.2 57.2836 78.0937C52.9146 78.9874 50.0975 83.2526 50.9915 87.6202C51.8855 91.9878 56.152 94.804 60.521 93.9103Z"
                    fill="#FFB6B6"/>
                <path
                    d="M57.0041 96.8405L64.446 96.4012C64.7598 96.3827 65.0716 96.4625 65.3379 96.6296C65.6042 96.7966 65.8118 97.0425 65.9317 97.333L68.0347 102.43L76.7751 109.651C76.7751 109.651 80.2357 114.054 80.2438 119.739C80.2518 125.425 81.4715 138.584 81.4715 138.584C81.4715 138.584 76.421 131.478 73.3037 133.368C72.7733 133.689 72.5868 134.347 72.0313 134.679C71.7542 134.845 71.114 134.684 70.8342 134.85C66.5386 137.399 62.0587 139.89 61.6401 139.453C60.8183 138.596 59.8213 134.214 59.8213 134.214L53.0093 109.194C52.64 107.877 52.5666 106.495 52.7945 105.147C53.0224 103.799 53.5459 102.518 54.3274 101.396C54.8033 100.726 55.302 100.228 55.7653 100.145C56.8058 99.9239 56.2523 97.5928 57.0041 96.8405Z"
                    fill="#CE9FFC"/>
                <path d="M120.309 209.52L123.45 207.337L116.334 193.915L111.698 197.137L120.309 209.52Z"
                      fill="#FFB6B6"/>
                <path
                    d="M120.784 200.514L115.861 204.913C108.032 196.194 102.115 183.964 97.0994 170.076C83.9593 166.229 74.0499 160.024 65.3991 152.657C62.4644 150.175 60.2644 146.986 62.7673 143.962C62.8843 143.82 63.0065 143.684 63.1339 143.552C66.0891 140.515 73.5835 141.094 77.9728 138.312L87.7781 148.79L87.1614 151.599L105.579 157.835C106.465 158.146 107.281 158.629 107.979 159.256C108.678 159.883 109.245 160.643 109.649 161.49C110.053 162.338 110.284 163.257 110.331 164.194C110.377 165.132 110.238 166.069 109.92 166.953C109.851 167.152 109.773 167.349 109.688 167.542L120.784 200.514Z"
                    fill="#2F2E41"/>
                <path
                    d="M118.477 208.635L123.506 204.676L125.068 206.923L132.451 206.901C132.815 206.9 133.171 207.015 133.465 207.23C133.76 207.445 133.977 207.749 134.087 208.096C134.197 208.444 134.192 208.817 134.074 209.162C133.956 209.507 133.731 209.806 133.432 210.014L127.186 214.354L124.564 212.879L125.689 215.394L123.334 217.031L118.477 208.635Z"
                    fill="#2F2E41"/>
                <path
                    d="M67.9933 159.897C67.4845 159.681 67.0293 159.357 66.6596 158.946C66.29 158.535 66.0149 158.048 65.8538 157.52C65.6928 156.991 65.6496 156.434 65.7273 155.886C65.805 155.339 66.0018 154.816 66.3038 154.353L49.4564 128.937L56.4182 128.408L71.7838 153.37C72.5279 153.929 73.0412 154.741 73.2264 155.653C73.4116 156.564 73.2558 157.512 72.7886 158.317C72.3213 159.121 71.5751 159.727 70.6913 160.018C69.8076 160.309 68.8476 160.266 67.9933 159.897Z"
                    fill="#FFB6B6"/>
                <path
                    d="M58.3066 103.644C58.3066 103.644 54.3667 100.843 50.7256 105.01C47.0844 109.177 47.3884 126.419 47.3542 129.199C47.3435 130.07 48.6011 131.948 50.3338 134.118C51.1117 135.092 51.9853 136.125 52.8829 137.152C53.4023 137.746 52.9547 138.983 53.4763 139.561C53.995 140.135 55.4829 140.05 55.9763 140.583C58.3182 143.114 60.2207 145.04 60.2207 145.04L67.8446 145.133C67.9306 139.468 64.0495 134.411 59.0633 129.523L58.3066 103.644Z"
                    fill="#CE9FFC"/>
                <path
                    d="M68.9944 82.0809C68.6956 82.2284 68.4058 82.3707 68.121 82.5053C66.9784 83.0668 65.7976 83.547 64.5874 83.9423C64.386 83.3947 64.0788 82.8921 63.6832 82.4631C63.7845 83.0556 63.7329 83.664 63.5334 84.231C61.7015 84.6845 59.7786 84.6103 57.9871 84.0169C59.1587 86.1855 60.0243 90.7551 59.9613 94.8303C59.9338 96.9851 59.6421 99.0012 59.005 100.45L58.1863 97.9603C58.1104 97.0709 57.9578 96.1897 57.7303 95.3265C57.1814 92.9044 56.6689 94.5239 55.9145 95.7246L50.638 96.877C50.6288 96.8157 50.6221 96.7507 50.6129 96.6895C50.6004 96.6123 50.588 96.5351 50.5723 96.4586C50.4741 95.9639 50.2949 95.4888 50.0419 95.0524C50.0151 95.637 49.8425 96.2055 49.5398 96.7063C49.5315 96.7194 49.5243 96.7331 49.5183 96.7473C49.4246 96.8989 49.3211 97.0443 49.2087 97.1826C49.2034 97.1863 49.1987 97.1906 49.1947 97.1955C45.7881 97.6512 43.4 80.0893 48.2247 79.7625C48.365 79.753 48.3916 79.5391 48.4786 79.4283C48.5656 79.3175 48.65 79.2105 48.7408 79.1022C48.7525 79.092 48.7623 79.0799 48.77 79.0664C49.0279 78.7678 49.2948 78.4807 49.577 78.2038C49.6603 78.1236 49.7467 78.0428 49.8332 77.962C51.1574 76.747 52.7336 75.8393 54.4493 75.3038C54.6431 75.2442 54.8439 75.1865 55.0486 75.1314C55.2508 75.0801 55.4568 75.0313 55.664 74.9889L55.7342 74.9745C55.8331 74.9543 55.9358 74.9366 56.0353 74.9196C56.1603 74.8973 56.286 74.8783 56.4092 74.863C56.8715 75.096 57.2672 75.4425 57.559 75.8701L60.9299 75.1806C61.0192 75.1623 61.1092 75.1472 61.1998 75.1353L61.2062 75.134C65.4147 74.4293 68.1206 77.8119 68.9944 82.0809Z"
                    fill="#2F2E41"/>
                <path
                    d="M50.6219 78.9759C49.9001 79.4967 49.1408 80.0333 48.26 80.1623C47.3793 80.2914 46.3482 79.8658 46.1044 79.0099C45.8064 77.9634 46.7517 77.0106 47.6012 76.3305L50.2953 74.1739C50.7886 73.7365 51.3455 73.3765 51.947 73.1063C52.2491 72.9734 52.5773 72.9101 52.9071 72.9212C53.237 72.9322 53.5602 73.0172 53.8528 73.17C54.1423 73.3654 54.377 73.6318 54.5344 73.9435C54.6919 74.2553 54.7668 74.6022 54.7522 74.9512C54.7138 75.6506 54.4829 76.3258 54.085 76.9024C53.5849 77.6816 52.9322 78.3516 52.1663 78.8721C51.4004 79.3926 50.537 79.7528 49.6282 79.931"
                    fill="#2F2E41"/>
                <path
                    d="M71.0547 135.005H51.5232C50.8325 135.005 50.1701 135.279 49.6817 135.767C49.1933 136.255 48.9189 136.918 48.9189 137.608C48.9189 138.299 49.1933 138.961 49.6817 139.449C50.1701 139.937 50.8325 140.212 51.5232 140.212H58.6847V160.388H63.8931V140.212H71.0547C71.7454 140.212 72.4078 139.937 72.8962 139.449C73.3846 138.961 73.6589 138.299 73.6589 137.608C73.6589 136.918 73.3846 136.255 72.8962 135.767C72.4078 135.279 71.7454 135.005 71.0547 135.005Z"
                    fill="#3F3D56"/>
                <path
                    d="M143.212 217.575L0.387592 217.675C0.284989 217.674 0.186692 217.633 0.114245 217.561C0.0417989 217.488 0.00111389 217.39 0.00111389 217.287C0.00111389 217.185 0.0417989 217.086 0.114245 217.014C0.186692 216.941 0.284989 216.9 0.387592 216.9L143.212 216.8C143.315 216.8 143.413 216.841 143.486 216.913C143.558 216.986 143.599 217.085 143.599 217.187C143.599 217.29 143.558 217.388 143.486 217.461C143.413 217.533 143.315 217.574 143.212 217.575Z"
                    fill="#CACACA"/>
                <path d="M184.775 174.354L187.955 177.817L202.833 167.206L198.14 162.094L184.775 174.354Z"
                      fill="#A0616A"/>
                <path
                    d="M191.356 179.256V179.252L191.034 175.793L190.887 175.725L190.031 175.318L185.991 173.408L185.907 173.317L185.096 172.432L180.607 176.551L186.688 183.177L190.858 187.72L190.998 187.59C191.57 187.066 192.032 186.435 192.359 185.733C192.687 185.031 192.872 184.271 192.906 183.497C192.939 182.723 192.819 181.95 192.553 181.222C192.287 180.494 191.881 179.826 191.356 179.256H191.356Z"
                    fill="#2F2E41"/>
                <path d="M168.303 208.908L172.802 210.275L180.216 193.575L173.575 191.558L168.303 208.908Z"
                      fill="#A0616A"/>
                <path
                    d="M179.953 212.647C179.59 211.962 179.095 211.357 178.496 210.865C177.897 210.373 177.206 210.006 176.463 209.784L174.422 206.969L174.263 206.982L168.869 207.476L168.748 207.44L167.603 207.092L165.832 212.921L180.34 217.327L180.396 217.141C180.622 216.4 180.699 215.621 180.624 214.85C180.548 214.079 180.32 213.33 179.953 212.647Z"
                    fill="#2F2E41"/>
                <path
                    d="M205.406 121.47C205.406 121.47 234.134 149.198 215.973 157.781H210.36C210.36 157.781 208.673 151.79 204.811 146.738C204.28 146.044 202.294 146.185 201.742 145.509C198.373 141.38 194.721 137.834 192.198 137.975L192.802 157.133L193.778 163.967C194.291 167.5 179.819 198.346 179.819 198.346L170.723 194.772C170.723 194.772 180.2 169.366 180.311 165.703L172.716 125.431L205.406 121.47Z"
                    fill="#2F2E41"/>
                <path
                    d="M207.802 122.837C207.655 123.046 207.464 123.219 207.242 123.345C206.876 123.552 206.479 123.699 206.067 123.781C199.598 125.249 178.469 128.291 172.983 126.84C172.31 126.664 171.87 126.417 171.727 126.091C171.268 125.063 171.327 120.719 171.597 115.473C171.646 114.49 172.73 113.426 172.795 112.398C172.84 111.626 171.867 110.901 171.919 110.116C172.277 104.812 172.739 99.4068 173.049 95.9899C173.244 93.7933 173.377 92.42 173.377 92.42L170.041 71.3879L170.197 71.1308L170.373 71.043L181.131 65.7191L181.717 64.2221C181.887 63.7911 182.182 63.4209 182.565 63.1593C182.947 62.8976 183.399 62.7566 183.863 62.7545L190.949 62.7252C191.317 62.7232 191.68 62.8089 192.008 62.9753C192.335 63.1417 192.619 63.384 192.834 63.6819L194.755 66.3406L207.45 74.4762L207.401 74.6259L201.444 92.7487L201.415 94.161V94.1642L201.194 105.521L201.809 107.061L201.115 109.586C201.115 109.586 201.434 112.538 203.143 114.731C204.693 116.723 206.666 118.799 207.558 120.484V120.488C208.052 121.418 208.218 122.225 207.802 122.837Z"
                    fill="#CE9FFC"/>
                <path
                    d="M217.352 156.134L215.817 158.073C215.817 158.073 201.694 167.951 201.209 168.874L198.977 170.985L193.577 165.214L196.385 161.695C198.633 158.879 201.537 156.657 204.842 155.223L209.146 151.405L217.352 156.134Z"
                    fill="#2F2E41"/>
                <path
                    d="M208.379 128.412L205.807 103.527L205.128 93.2848L212.326 91.9836C212.326 91.9836 212.041 99.3069 212.984 103.439C213.915 107.516 213.108 127.866 213.05 128.41C213.579 128.882 213.951 129.504 214.119 130.193C214.286 130.882 214.24 131.605 213.988 132.268C213.735 132.93 213.287 133.5 212.703 133.902C212.119 134.305 211.426 134.52 210.717 134.52C210.008 134.521 209.316 134.306 208.731 133.904C208.147 133.503 207.698 132.933 207.445 132.271C207.191 131.609 207.145 130.885 207.312 130.196C207.478 129.507 207.85 128.885 208.379 128.412Z"
                    fill="#A0616A"/>
                <path
                    d="M215.4 108.684L206.148 110.598L205.339 107.374L204.396 106.374L202.512 96.5058L199.808 90.7253L200.511 70.9579L207.377 74.5985L207.386 74.605C208.436 75.7447 209.16 77.3758 210.363 79.0126C214.156 84.1701 214.576 96.7025 214.462 103.567C214.46 103.68 214.573 103.799 214.709 103.924C215.355 104.516 215.776 105.314 215.902 106.181C216.028 107.048 215.851 107.932 215.4 108.684V108.684Z"
                    fill="#CE9FFC"/>
                <path
                    d="M166.482 129.388L169.054 104.503L169.733 94.2611L162.534 92.9599C162.534 92.9599 162.819 100.283 161.877 104.415C160.946 108.492 161.753 128.842 161.811 129.386C161.282 129.858 160.909 130.48 160.742 131.169C160.574 131.858 160.62 132.582 160.873 133.244C161.126 133.906 161.574 134.476 162.158 134.879C162.742 135.281 163.434 135.496 164.143 135.497C164.853 135.497 165.545 135.282 166.129 134.88C166.714 134.479 167.162 133.909 167.416 133.247C167.669 132.585 167.716 131.862 167.549 131.172C167.382 130.483 167.01 129.861 166.482 129.388Z"
                    fill="#A0616A"/>
                <path
                    d="M175.054 91.7008L173.049 95.9899L172.349 97.4835L170.464 107.35L169.523 108.349L168.713 111.574L168.332 111.496L161.463 110.074L159.461 109.661C159.01 108.909 158.832 108.025 158.958 107.157C159.084 106.29 159.505 105.493 160.151 104.9C160.288 104.776 160.402 104.656 160.399 104.542C160.285 97.6788 160.705 85.1468 164.497 79.9888C165.701 78.3519 166.424 76.7216 167.476 75.5826C167.479 75.5794 167.479 75.5761 167.482 75.5761L170.041 71.3879L170.197 71.1308L170.373 71.043L175.054 91.7008Z"
                    fill="#CE9FFC"/>
                <path
                    d="M187.201 60.7083C192.409 60.7083 196.63 56.4881 196.63 51.2822C196.63 46.0763 192.409 41.8561 187.201 41.8561C181.994 41.8561 177.772 46.0763 177.772 51.2822C177.772 56.4881 181.994 60.7083 187.201 60.7083Z"
                    fill="#A0616A"/>
                <path
                    d="M197.802 47.1472C197.769 45.5396 197.587 43.4829 196.46 41.8428C195.51 40.463 193.593 39.3696 191.805 39.9163C191.825 39.559 191.746 39.2032 191.576 38.8884C191.406 38.5736 191.152 38.3121 190.842 38.133C190.165 37.7197 189.351 37.6448 188.632 37.5765C186.818 37.4073 184.94 37.2315 183.104 37.5797C181.043 37.9703 179.357 39.0311 178.482 40.4923L178.462 40.5378L178.283 41.4035C178.259 41.5196 178.21 41.6291 178.14 41.7246C178.07 41.8201 177.98 41.8993 177.876 41.9567C177.772 42.0149 177.657 42.0495 177.538 42.0579C177.419 42.0664 177.3 42.0485 177.189 42.0055C177.068 41.9599 176.936 41.9469 176.808 41.9676C176.68 41.9883 176.56 42.0421 176.459 42.1236C176.358 42.2051 176.28 42.3115 176.233 42.4324C176.186 42.5532 176.171 42.6842 176.19 42.8126L176.239 43.1575L175.721 43.0599C175.591 43.0347 175.457 43.0447 175.332 43.0888C175.207 43.1329 175.097 43.2094 175.011 43.3106C174.926 43.4118 174.869 43.5339 174.847 43.6643C174.825 43.7947 174.838 43.9287 174.885 44.0524L175.028 44.4462C174.921 44.4476 174.815 44.4705 174.717 44.5136C174.62 44.5566 174.531 44.6189 174.458 44.6968C174.354 44.8102 174.288 44.9539 174.27 45.1071C174.253 45.2604 174.284 45.4152 174.36 45.5494C174.941 46.5393 175.763 47.3657 176.75 47.951C177.194 48.2183 177.672 48.4231 178.172 48.5595C178.277 48.5888 178.384 48.6148 178.495 48.6376C182.491 49.4987 186.612 49.6222 190.653 49.0021C190.415 49.7991 190.323 50.6326 190.383 51.4623C190.513 52.5004 191.122 53.3562 191.936 53.6426C192.12 53.705 192.309 53.7507 192.502 53.7793C192.686 53.8092 192.868 53.8538 193.046 53.9127C193.307 54.0086 193.539 54.1719 193.718 54.3861C193.896 54.6003 194.015 54.8578 194.062 55.1325C194.109 55.4073 194.082 55.6896 193.985 55.9508C193.888 56.212 193.723 56.443 193.508 56.6202C193.508 56.6202 194.361 57.3264 194.468 57.2906C194.887 57.1055 195.238 56.7936 195.471 56.3989C197.095 54.0624 197.88 50.9514 197.802 47.1472Z"
                    fill="#2F2E41"/>
            </g>
            <defs>
                <clipPath id="clip0_4_112">
                    <rect width="252" height="218" fill="white"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default CompanyTeamIcon
