import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
           main: '#010851'
        },
        text: {
           primary: '#010851'
        }
    },
    shape: {
        borderRadius: 12
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
                    textTransform: 'none'
                },
                containedSizeLarge: {
                    padding: '12px 32px',
                    fontWeight: '600',
                }
            }
        }
    }
});

export default theme;
