import React, {useEffect, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import themeL from './theme'
import themeD from './themeDark'
import {Theme} from "@mui/material";

type Props = {
    children: React.ReactNode
    theme?: Theme,
}

export default function ThemeProvider({
                                          theme = themeL,
                                          children
                                      }: Props) {
    return (
        <MuiThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline/>
            {children}
        </MuiThemeProvider>
    )
}
