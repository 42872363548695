export const navigations = [
    {
        id: '#company',
        title: 'О компании',
    },
    {
        id: '#products',
        title: 'Продукты',
    },
];
