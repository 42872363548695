import React from "react";
import {Box, Grid, Paper, Stack, Typography} from "@mui/material";
import {CompanyResultIcon, CompanyTeamIcon} from "../Icons";

const arr = [
    {
        title: 'Команда опытных специалистов с высокой экспертизой.',
        icon: <CompanyTeamIcon
            // @ts-ignore
            sx={{
                width: '100%',
                height: '220px',
                maxWidth: '250px'
            }}
        />,
    },
    {
        title: 'Ориентированы на результат и долгосрочное сотрудничество.',
        icon: <CompanyResultIcon
            // @ts-ignore
            sx={{
                width: '100%',
                height: '220px',
                maxWidth: '250px'
            }}
        />
    }
]

export default function AboutCompany() {
    return (
        <Grid
            container
            spacing={6}
            sx={{
                marginTop: '32px'
            }}
        >
            <Grid
                item
                lg={4}
                md={12}
                xs={12}
            >
                <Typography
                    id="company"
                    variant="h2"
                    sx={{
                        fontSize: '42px',
                        fontWeight: "bold",
                        marginBottom: '16px',
                        textAlign: {
                            lg: 'left',
                            xs: 'center'
                        }
                    }}
                >
                    О Компании
                </Typography>
                <Typography
                    fontSize={18}
                    sx={{
                        textAlign: {
                            lg: 'left',
                            xs: 'center'
                        }
                    }}
                >
                    Стартап компания основной деятельностью которой является создание программных решений в области информационной безопасности.
                    <br/>
                    Целевая аудитория бизнеса это крупный корпоративный сегмент.
                    <br/>
                    Компания нацелена на импортозамещение решений в области информационной безопасности.
                </Typography>
            </Grid>

            {arr.map((item, i) => (
                <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                >
                    <Paper
                        key={i}
                        sx={{
                            textAlign: 'center',
                            padding: '32px',
                            borderRadius: '32px',
                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                            marginTop: {
                                lg: i === 0 ? '60px !important' : 0,
                                xs: 0
                            },
                        }}
                    >
                        {item.icon}

                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: '16px',
                                fontWeight: 600,
                                marginTop: '16px'
                            }}

                        >
                            {item.title}
                        </Typography>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    )
}
