import {Box, Container, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import {LogoIcon} from "../Icons";
import {navigations} from "./constants";
import {Link} from "react-scroll";
import {AnchorLink} from "gatsby-plugin-anchor-links";

export default function Footer() {

    return (
        <Box
            sx={{
                margin: '80px 0 0 0',
                backgroundColor: '#1a1a1a',
                borderTop: '1px solid rgba(65,65,65,.8)',
                padding: '48px 0',
                color: 'white'
            }}
        >
            <Container>
                <Grid
                    spacing={{
                        xs: 2,
                        lg: 10
                    }}
                    container
                >
                    <Grid item xs={12} lg={6}>
                        <LogoIcon
                            htmlColor="white"
                            sx={{
                                fontSize: '90px'
                            }}
                        />

                        {/*<Typography*/}
                        {/*    variant="body1"*/}
                        {/*>*/}
                        {/*    Вы не можете защитить ИТ-активы, которые вы не видите или не знаете об их существовании*/}
                        {/*</Typography>*/}
                    </Grid>

                    <Grid item xs={12} sm={4} lg={2}>
                        <Box
                            pt={2}
                            sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2}}
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                fontSize="18px"
                            >
                                Навигация
                            </Typography>
                            {navigations.map((page) => (
                                <AnchorLink
                                    key={page.id}
                                    to={`/${page.id}`}
                                >
                                    {page.title}
                                </AnchorLink>
                            ))}
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} lg={2}>
                        <Box
                            pt={2}
                            sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2}}
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                fontSize="18px"
                            >
                                Продукты
                            </Typography>

                            <AnchorLink
                                to="/exploitdog"
                            >
                                ExploitDog
                            </AnchorLink>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} lg={2}>
                        <Box
                            pt={2}
                            sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2}}
                        >
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                fontSize="18px"
                            >
                                Контакты
                            </Typography>

                            <Typography fontWeight="bold" component="a" target="_blank"
                                        href="mailto:info@nir-it.ru">
                                info@nir-it.ru
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Divider
                    sx={{
                        margin: '32px 0',
                        backgroundColor: 'white',
                        opacity: '0.2'
                    }}
                />

                <Typography
                    variant="caption"
                >
                    ООО "Навигатор инновационных решений"
                </Typography>
            </Container>
        </Box>
    )
}
