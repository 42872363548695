import React from 'react'
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

const LogoIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
    return (
        <SvgIcon
            width="128" height="35" viewBox="0 0 128 35" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
            <path
                d="M41.144 0.151999C40.92 5.56 40.808 11.368 40.808 17.576C40.808 23.784 40.92 29.592 41.144 35H29.864V21.944H12.2V35H0.92C1.176 28.792 1.304 22.984 1.304 17.576C1.304 12.168 1.176 6.36 0.92 0.151999H12.2V13.208H29.864V0.151999H41.144ZM56.7425 0.199999L55.9265 27.128L67.5905 0.199999H85.2545C85.0305 5.608 84.9185 11.4 84.9185 17.576C84.9185 23.784 85.0305 29.592 85.2545 35H72.9185L73.7825 8.792L62.1665 35H44.9825C45.2385 28.792 45.3665 22.984 45.3665 17.576C45.3665 12.2 45.2385 6.408 44.9825 0.199999H56.7425ZM110.884 0.199999C117.252 0.199999 121.684 1.336 124.18 3.608C126.676 5.848 127.924 9.016 127.924 13.112C127.924 15.928 127.428 18.328 126.436 20.312C125.444 22.264 123.7 23.8 121.204 24.92C118.708 26.008 115.268 26.552 110.884 26.552H100.9V35H89.0919C89.3479 28.792 89.4759 22.984 89.4759 17.576C89.4759 12.168 89.3479 6.36 89.0919 0.151999H100.9V0.199999H110.884ZM107.524 17.288C110.18 17.288 112.164 17.192 113.476 17C114.82 16.776 115.748 16.392 116.26 15.848C116.804 15.304 117.076 14.504 117.076 13.448C117.076 12.392 116.82 11.608 116.308 11.096C115.828 10.552 114.916 10.184 113.572 9.992C112.26 9.8 110.244 9.704 107.524 9.704H100.9V17.288H107.524Z"
                fill="currentColor"/>
        </SvgIcon>
    )
}

export default LogoIcon
