import React from 'react'
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

type Props = {}

const ExploitDogTwoIcon: React.FC<Props> = (props: SvgIconProps) => {
    return (
        <SvgIcon
            width="810" height="740" viewBox="0 0 810 740" fill="none"
            {...props}
            style={{
                fill: 'none'
            }}
        >
                <g clipPath="url(#clip0_4_339)">
                    <path d="M513.182 585.376L520.879 602.626C514.623 601.631 508.804 606.972 508.674 613.83C508.543 620.689 514.123 627.825 520.381 628.8C519.185 634.125 514.024 636.473 509.545 638.625C505.066 640.777 500.238 644.347 500.571 650.021C500.839 654.577 504.608 658.6 508.611 660.217C512.614 661.834 516.814 661.586 520.868 661.314C514.725 667.737 514.126 679.81 519.521 688.472C524.916 697.133 535.69 701.397 543.757 698.063C538.057 705.151 539.296 718.13 546.37 725.444C553.501 732.817 563.836 733.531 572.872 732.754C595.88 730.81 617.804 722.126 635.898 707.789C642.805 702.303 649.257 695.851 653.197 687.435C657.138 679.02 658.299 668.365 654.628 658.788C655.618 670.785 667.632 680.26 678.44 680.79C689.247 681.32 698.683 675.236 706.702 668.298C717.366 659.071 727.239 646.137 725.775 630.178C725.051 622.278 721.598 614.679 718.212 607.32L711.648 593.051C708.998 587.291 706.287 581.433 702.299 576.599C698.31 571.765 692.773 568.023 687.233 568.059C688.5 562.274 689.772 556.274 688.711 550.128C687.649 543.982 683.584 537.618 678.055 536.179C671.02 534.347 665.057 540.837 657.981 540.225C651.173 539.636 645.546 532.807 638.934 530.1C636.212 528.924 633.206 528.566 630.283 529.071C627.361 529.576 624.649 530.923 622.48 532.945C618.289 537.035 616.542 543.944 618.046 550.482C619.364 556.209 622.87 561.261 624.998 566.784C627.126 572.308 627.457 579.41 623.369 582.426C618.437 586.066 610.93 581.113 607.076 575.121C603.223 569.128 601.011 561.872 596.138 556.832C590.387 550.884 581.384 549.49 575.463 553.631C569.542 557.771 567.277 567.044 570.262 574.927C572.703 581.372 578.294 587.891 576.355 593.961C574.306 600.375 565.338 599.562 559.928 595.029C554.519 590.496 550.927 583.668 545.583 579.033C540.156 574.326 533.069 572.264 526.848 573.581C523.692 574.313 520.771 575.827 518.355 577.985C515.94 580.142 514.106 582.873 513.025 585.926" fill="#E6E6E6">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            // from="0 60 70"
                            // to="360 60 70"
                            values="1; 1.02; 1"
                            dur="4s"
                            repeatCount="indefinite" />
                    </path>

                    <path d="M637.801 536.899L621.83 544.315C619.43 545.5 616.707 545.867 614.078 545.36C611.76 544.812 610.193 543.395 609.781 541.471L606.065 524.123C605.363 520.85 608.075 516.978 612.512 514.918L621.332 510.822C625.77 508.761 630.479 509.187 632.529 511.833L643.397 525.86C644.603 527.415 644.675 529.526 643.599 531.651C642.292 533.986 640.255 535.829 637.801 536.899Z" fill="#3F3D56"/>

                    <path d="M142.586 216.263C157.6 175.189 181.031 137.701 211.378 106.201C237.124 79.4864 277.195 80.5964 277.195 80.5964L277.423 80.3125C287.067 68.4257 300.009 59.6502 314.623 55.0868C329.238 50.5235 344.876 50.3756 359.575 54.6617C363.395 55.7575 366.334 56.2433 367.731 55.7552C393.212 46.8416 392.015 26.7227 413.999 10.5151C452.492 -17.8636 580.17 14.6436 598.174 66.8636C612.766 109.187 677.9 129.7 702.234 162.074C706.04 167.136 709.773 172.257 713.434 177.436C715.151 179.843 716.849 182.267 718.527 184.709C735.768 209.665 751.235 235.799 764.813 262.92C773.718 280.639 781.457 298.92 787.981 317.645C813.359 391.798 804.68 447.046 734.706 433.397C726.422 431.777 718.338 430.327 710.453 429.049C699.034 427.186 688.014 425.655 677.392 424.457C663.088 422.831 649.48 421.766 636.567 421.261C632.226 421.086 627.964 420.969 623.781 420.91C561.941 420.053 516.07 431.686 481.125 449.745C475.201 452.798 469.587 456.029 464.284 459.436C456.183 464.612 448.448 470.338 441.135 476.576C431.013 485.257 421.68 494.816 413.244 505.141L412.78 505.708C371.907 555.679 358.455 611.001 330.024 620.741C208.486 662.386 131.618 511.422 123.736 363.746C123.337 356.305 123.118 348.884 123.076 341.486C123.051 338.178 123.068 334.88 123.125 331.59C123.249 323.008 123.628 314.481 124.262 306.057C124.499 302.836 124.773 299.633 125.086 296.446C125.43 293.01 125.804 289.594 126.237 286.199C129.329 261.317 134.738 237.643 142.586 216.263Z" fill="#CE9FFC">
                        <animateTransform
                            attributeName="transform"
                            type="translate"
                            // from="0 60 70"
                            // to="360 60 70"
                            values="0 0; -8 8; -8 0; 0 8; 0 0"
                            dur="5s"
                            repeatCount="indefinite" />
                    </path>

                    <path d="M519.293 297.512C509.586 302.029 509.426 322.261 512.47 328.816L517.986 340.681L553.143 324.354L547.628 312.489C544.578 305.936 529.006 293.011 519.293 297.512Z" fill="#3F3D56"/>
                    <path d="M424.814 467.867C424.196 467.867 423.586 467.717 423.037 467.432C422.489 467.146 422.017 466.732 421.663 466.226C421.308 465.719 421.081 465.134 421.001 464.521C420.921 463.908 420.99 463.285 421.202 462.704C421.374 462.229 421.638 461.793 421.98 461.422C422.322 461.05 422.734 460.75 423.193 460.539L604.758 376.222C605.215 376.009 605.711 375.889 606.215 375.867C606.719 375.846 607.223 375.924 607.697 376.097C608.171 376.27 608.606 376.535 608.978 376.877C609.35 377.218 609.65 377.629 609.863 378.086C610.292 379.011 610.336 380.067 609.986 381.024C609.636 381.98 608.921 382.759 607.997 383.19H607.997L426.432 467.506C425.925 467.743 425.373 467.866 424.814 467.867Z" fill="#3F3D56"/>
                    <path d="M566.839 331.922L514.939 356.023L596.352 531.166L648.252 507.064L566.839 331.922Z" fill="#E6E6E6"/>
                    <path d="M372.012 111.446C362.305 115.963 362.145 136.194 365.189 142.75L370.704 154.615L405.862 138.288L400.347 126.423C397.297 119.87 381.725 106.945 372.012 111.446Z" fill="#3F3D56"/>
                    <path d="M589.49 557.033L573.52 564.449C571.119 565.634 568.396 566.001 565.768 565.494C563.449 564.946 561.882 563.529 561.471 561.605L557.754 544.257C557.053 540.984 559.764 537.112 564.202 535.052L573.022 530.956C577.459 528.895 582.169 529.321 584.218 531.968L595.086 545.994C596.292 547.55 596.365 549.66 595.289 551.785C593.981 554.12 591.945 555.963 589.49 557.033Z" fill="#3F3D56"/>
                    <path d="M540.389 581.383L524.418 588.799C522.017 589.984 519.295 590.351 516.666 589.844C514.347 589.296 512.781 587.879 512.369 585.955L508.653 568.607C507.951 565.334 510.663 561.462 515.1 559.401L523.92 555.306C528.357 553.245 533.067 553.671 535.117 556.317L545.985 570.344C547.19 571.899 547.263 574.01 546.187 576.134C544.879 578.47 542.843 580.313 540.389 581.383Z" fill="#3F3D56"/>
                    <path d="M433.406 175.647L381.506 199.749L546.073 553.78L597.973 529.678L433.406 175.647Z" fill="#E6E6E6"/>
                    <path d="M487.257 358.149L481.433 345.341L484.786 343.796L490.592 356.563L574.662 542.878L571.313 544.433" fill="#3F3D56"/>
                    <path d="M552.94 347.503L547.117 334.695L550.469 333.149L556.275 345.917L640.346 532.232L636.997 533.787" fill="#3F3D56"/>
                    <path d="M457.903 203.98L387.581 236.636C383.104 229.674 369.607 193.998 372.638 183.413L370.363 173.319L418.919 150.77L425.418 158.761C435.066 161.72 453.847 195.23 457.903 203.98Z" fill="#E6E6E6"/>
                    <path d="M420.948 151.202L370.841 174.471C368.992 175.33 367.022 175.014 366.442 173.766C366.359 173.586 366.307 173.393 366.289 173.195L363.696 145.543C363.585 144.359 364.598 143.017 366.147 142.298L399.376 126.867C400.924 126.148 402.604 126.239 403.438 127.088L422.908 146.902C423.904 147.915 423.363 149.65 421.7 150.776C421.461 150.938 421.21 151.08 420.948 151.202Z" fill="#E6E6E6"/>
                    <path d="M460.248 367.843C459.706 367.845 459.165 367.781 458.638 367.653C457.895 367.499 457.197 367.177 456.597 366.712C455.998 366.247 455.513 365.651 455.179 364.969L424.545 299.067C423.278 296.338 424.98 292.849 428.34 291.289L473.196 270.458C476.556 268.897 480.321 269.848 481.59 272.576L512.224 338.479C512.53 339.173 512.673 339.928 512.642 340.686C512.611 341.444 512.407 342.185 512.046 342.852C511.259 344.362 509.985 345.562 508.429 346.256L463.573 367.087C462.533 367.577 461.398 367.835 460.248 367.843Z" fill="#3F3D56"/>
                    <path d="M466.383 377.819L414.483 401.92L495.896 577.063L547.795 552.961L466.383 377.819Z" fill="#E6E6E6"/>
                    <path d="M402.443 154.091L387.223 161.159C384.286 162.523 380.324 160.25 378.392 156.093C376.459 151.936 377.277 147.444 380.214 146.08L395.434 139.012C398.371 137.648 402.333 139.921 404.265 144.078C406.197 148.234 405.38 152.727 402.443 154.091Z" fill="#3F3D56"/>
                    <path d="M419.849 342.939C410.143 347.456 409.983 367.688 413.027 374.243L418.542 386.108L453.7 369.781L448.185 357.916C445.135 351.363 429.563 338.438 419.849 342.939Z" fill="#3F3D56"/>
                    <path d="M258.616 302.556C255.188 304.839 251.68 299.405 255.174 297.224C258.601 294.941 262.11 300.374 258.616 302.556Z" fill="#E6E6E6"/>
                    <path d="M615.437 224.483C612.009 226.766 608.501 221.333 611.995 219.151C615.423 216.868 618.931 222.302 615.437 224.483Z" fill="#E6E6E6"/>
                    <path d="M372.231 286.586C368.803 288.869 365.295 283.436 368.788 281.254C372.216 278.971 375.725 284.405 372.231 286.586Z" fill="#E6E6E6"/>
                    <path d="M258.616 499.511C255.188 501.794 251.68 496.361 255.174 494.179C258.601 491.897 262.11 497.33 258.616 499.511Z" fill="#E6E6E6"/>
                    <path d="M433.476 55.0307C430.048 57.3136 426.54 51.8801 430.034 49.6986C433.462 47.4158 436.97 52.8493 433.476 55.0307Z" fill="#E6E6E6"/>
                    <path d="M264.83 159.719C261.402 162.002 257.893 156.568 261.387 154.387C264.815 152.104 268.323 157.537 264.83 159.719Z" fill="#E6E6E6"/>
                    <path d="M507.395 129.707L505.214 129.228L505.692 127.048L504.238 126.729L503.76 128.91L501.579 128.433L501.26 129.886L503.441 130.364L502.963 132.544L504.417 132.862L504.896 130.682L507.077 131.16L507.395 129.707Z" fill="#E6E6E6"/>
                    <path d="M152.263 311.383L150.082 310.905L150.56 308.725L149.106 308.407L148.628 310.587L146.446 310.109L146.127 311.562L148.309 312.041L147.831 314.221L149.285 314.539L149.764 312.359L151.945 312.836L152.263 311.383Z" fill="#E6E6E6"/>
                    <path d="M599.521 404.057C597.706 404.897 595.632 404.984 593.753 404.297C591.874 403.611 590.345 402.208 589.5 400.396L580.524 381.085C579.684 379.272 579.598 377.2 580.285 375.323C580.973 373.447 582.378 371.92 584.191 371.078C586.004 370.236 588.078 370.147 589.956 370.832C591.834 371.517 593.364 372.919 594.208 374.731L603.184 394.041C604.025 395.855 604.111 397.928 603.425 399.806C602.738 401.684 601.334 403.213 599.521 404.057Z" fill="#3F3D56"/>
                    <path d="M446.851 475.032C445.036 475.873 442.962 475.959 441.083 475.273C439.204 474.586 437.675 473.183 436.83 471.371L427.854 452.061C427.014 450.247 426.928 448.175 427.616 446.298C428.303 444.422 429.708 442.895 431.521 442.053C433.334 441.211 435.408 441.122 437.286 441.807C439.164 442.492 440.694 443.894 441.538 445.706L450.515 465.016C451.355 466.83 451.442 468.903 450.755 470.781C450.068 472.659 448.664 474.188 446.851 475.032Z" fill="#3F3D56"/>
                    <path d="M585.694 361.867L525.508 389.817C521.676 383.858 510.125 353.324 512.719 344.265L513.992 334.131L549.928 316.508L557.891 323.167C569.111 319.375 585.603 347.163 585.694 361.867Z" fill="#E6E6E6"/>
                    <path d="M557.751 359.929L542.532 366.997C539.595 368.361 535.126 364.999 532.571 359.503C530.016 354.007 530.327 348.425 533.264 347.061L548.484 339.993C551.421 338.629 555.89 341.991 558.445 347.487C560.999 352.983 560.688 358.565 557.751 359.929Z" fill="#3F3D56"/>
                    <path d="M428.674 396.298L422.851 383.49L426.203 381.945L432.009 394.712L516.08 581.027L512.731 582.582" fill="#3F3D56"/>
                    <path d="M486.25 407.294L426.065 435.244C417.186 430.661 403.616 400.678 413.276 389.692L414.549 379.558L450.485 361.935L458.448 368.594C466.705 371.126 482.779 399.805 486.25 407.294Z" fill="#E6E6E6"/>
                    <path d="M457.069 402.691L441.85 409.759C438.913 411.123 434.722 408.359 432.508 403.597C430.295 398.836 430.884 393.852 433.821 392.488L449.041 385.42C451.978 384.056 456.169 386.821 458.382 391.582C460.595 396.343 460.006 401.328 457.069 402.691Z" fill="#3F3D56"/>

                    <path d="M152.041 273.116C202.533 273.116 243.465 232.204 243.465 181.736C243.465 131.268 202.533 90.3555 152.041 90.3555C101.549 90.3555 60.6165 131.268 60.6165 181.736C60.6165 232.204 101.549 273.116 152.041 273.116Z" fill="#FF6584">
                        <animateTransform
                            attributeName="transform"
                            type="translate"
                            // from="0 60 70"
                            // to="360 60 70"
                            values="0 0; 400 -20; 0 0"
                            dur="15s"
                            repeatCount="indefinite" />
                    </path>

                    <path opacity="0.3" d="M526.893 276.912L582.452 114.727L521.445 274.971C521.143 275.351 520.936 275.798 520.842 276.274C520.748 276.75 520.768 277.242 520.902 277.709C521.035 278.175 521.279 278.603 521.611 278.957C521.943 279.311 522.355 279.581 522.813 279.744C523.27 279.907 523.76 279.959 524.241 279.895C524.723 279.831 525.182 279.653 525.581 279.376C525.98 279.099 526.307 278.731 526.535 278.303C526.763 277.874 526.886 277.398 526.893 276.912H526.893Z" fill="#F0F0F0"/>
                    <path d="M668.515 180.239C657.639 183.852 645.582 187.401 632.344 190.886C563.869 208.906 472.085 222.962 373.901 230.466C275.717 237.97 182.862 238.024 112.442 230.62C78.1382 227.013 51.0058 221.826 31.799 215.2C11.4642 208.187 0.779275 199.735 0.0405305 190.081C-1.39806 171.273 35.7338 154.719 67.1371 144.144L67.9876 146.666C24.8626 161.188 1.67507 176.535 2.69601 189.878C3.99574 206.873 44.0982 220.758 112.721 227.973C182.986 235.361 275.669 235.304 373.698 227.812C471.727 220.32 563.342 206.292 631.666 188.312C698.391 170.754 735.917 150.935 734.617 133.94C733.537 119.83 705.709 107.759 656.26 99.95L656.676 97.3213C692.606 102.995 735.756 113.919 737.272 133.737C738.01 143.392 728.735 153.369 709.704 163.391C698.855 169.104 685.071 174.74 668.515 180.239Z" fill="#3F3D56"/>

                    <path d="M695.38 342.311C700.626 342.311 704.879 338.06 704.879 332.816C704.879 327.572 700.626 323.321 695.38 323.321C690.133 323.321 685.88 327.572 685.88 332.816C685.88 338.06 690.133 342.311 695.38 342.311Z" fill="#FF6584">
                        <animate attributeName="fill" dur="3s" repeatCount="indefinite" begin="2s" values="#FF6584;transparent;#FF6584" fill="freeze" />
                    </path>

                    <path d="M755.738 599.595C760.984 599.595 765.237 595.344 765.237 590.1C765.237 584.857 760.984 580.605 755.738 580.605C750.491 580.605 746.238 584.857 746.238 590.1C746.238 595.344 750.491 599.595 755.738 599.595Z" fill="#F2F2F2"/>
                    <path d="M431.758 657.408C441.987 657.408 450.279 649.119 450.279 638.895C450.279 628.671 441.987 620.383 431.758 620.383C421.529 620.383 413.237 628.671 413.237 638.895C413.237 649.119 421.529 657.408 431.758 657.408Z" fill="#F2F2F2"/>

                    <path d="M290.628 412.399C295.874 412.399 300.127 408.148 300.127 402.904C300.127 397.66 295.874 393.409 290.628 393.409C285.381 393.409 281.128 397.66 281.128 402.904C281.128 408.148 285.381 412.399 290.628 412.399Z" fill="#3F3D56">
                        <animate attributeName="fill" dur="3s" repeatCount="indefinite" begin="0s" values="#3F3D56;transparent;#3F3D56" fill="freeze" />
                    </path>

                    <path d="M609.992 424.757L650.978 435.361L676.117 484.714L633.618 486.38L609.992 424.757Z" fill="#E6E6E6"/>
                    <path d="M515.389 353.61L537.947 376.169L595.24 499.98L584.691 509.089L515.389 353.61Z" fill="#CCCCCC"/>
                    <path d="M466.34 374.059L468.11 405.906L522.187 531.154L536.013 529.374L466.34 374.059Z" fill="#CCCCCC"/>
                    <path d="M464.864 502.713L442.79 538.823L459.063 591.761L490.762 563.418L464.864 502.713Z" fill="#E6E6E6"/>
                    <path d="M659.857 524.902C648.396 524.902 635.834 524.469 622.172 523.603C551.506 519.118 459.971 503.523 364.43 479.694C268.889 455.865 180.756 426.646 116.266 397.421C84.8508 383.185 60.74 369.709 44.6039 357.367C27.5199 344.302 20.0471 332.913 22.3923 323.518C26.9617 305.217 67.4219 301.213 100.559 301.077L100.57 303.738C55.0641 303.925 28.2177 311.179 24.9763 324.163C20.8472 340.7 54.5219 366.518 117.365 394.997C181.714 424.157 269.685 453.32 365.075 477.112C460.464 500.903 551.83 516.471 622.341 520.947C691.201 525.318 733.065 518.34 737.194 501.803C740.621 488.072 718.023 467.845 673.561 444.847L674.785 442.483C707.091 459.194 744.592 483.162 739.777 502.447C737.432 511.842 725.482 518.386 704.259 521.897C692.162 523.898 677.302 524.902 659.857 524.902Z" fill="#3F3D56"/>
                </g>
                <defs>
                    <clipPath id="clip0_4_339">
                        <rect width="801" height="733" fill="white"/>
                    </clipPath>
                </defs>
        </SvgIcon>
    )
}

export default ExploitDogTwoIcon
