import React, {useEffect} from "react";
import {
    Box,
    Grid,
    OutlinedInputProps,
    Paper,
    TextField,
    styled,
    TextFieldProps,
    Typography,
    alpha, InputProps, Button
} from "@mui/material";
import {DoneIcon, TelegramIcon} from "../Icons";
import {useFormik} from "formik";
import {IMaskInput} from 'react-imask';
import * as Yup from 'yup'
import MailIcon from "../Icons/MailIcon";
import {Player} from "@lottiefiles/react-lottie-player";

type Props = {
    mode?: 'light' | 'dark'
}

export default function CallForm({
                                     mode = 'dark'
                                 }: Props) {

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://nir-it.youtrack.cloud/static/simplified/form/form-entry.js";
        script.setAttribute('id', 'e6a8ab8d-f40a-4613-bc7a-0f486c71f174')
        script.setAttribute('data-yt-url', 'https://nir-it.youtrack.cloud')
        script.setAttribute('data-theme', mode)
        script.setAttribute('data-lang', 'ru')

        script.async = true;

        document.getElementById('call-form')?.appendChild(script);
    }, []);
    return (
        <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
        >
            <Grid item
                  xs={12}
                  lg={6}
            >
                <Typography
                    id="call"
                    variant="h2"
                    fontWeight="bold"
                    sx={{
                        color: 'text.primary',
                        fontSize: '42px',
                        textAlign: {
                            lg: 'left',
                            xs: 'center'
                        }
                    }}
                >
                    Свяжитесь с нами
                </Typography>

                <Typography
                    variant="body1"
                    sx={{
                        color: 'text.primary',
                        margin: '16px 0 32px 0',
                        textAlign: {
                            lg: 'left',
                            xs: 'center'
                        }
                    }}
                >
                    Получите полную пробную версию
                    <br/>
                    без ограничения по количеству сканируемых устройств.
                </Typography>

                <Box sx={{
                    position: 'relative'
                }}>
                    <Box
                        sx={{
                            display: {
                                md: 'block',
                                xs: 'none',
                            }
                            // right: '30px',
                            // top: '70px'
                        }}
                    >
                        <Player
                            autoplay
                            loop
                            src="/lottie/contact-us.json"
                            style={{opacity: 1}}
                        >
                        </Player>
                    </Box>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                lg={6}
            >
                <Box sx={{
                    backgroundColor: mode === 'dark' ? 'hsla(0,0%,8%,.5)' : 'none',
                    border: mode === 'dark' ? '1px solid rgba(65,65,65,.8)' : '1px solid rgb(197,209,219)',
                    borderRadius: '14px',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '20px 0',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Box
                        my={2}
                        id="call-form"
                    >
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
