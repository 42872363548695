import {Box, Button, IconButton, Menu, MenuItem, Theme, useMediaQuery} from "@mui/material";
import {navigations} from "../Landing/constants";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import React from "react";
import MenuIcon from "../Icons/MenuIcon";
import './style.css'

export default function Navigation() {
    const isShortMenu = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (isShortMenu) {
        return (
            <>
                <IconButton
                    size="large"
                    aria-label="Навигация по сайту"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    sx={{
                        marginLeft: 'auto'
                    }}
                >
                    <MenuIcon fontSize="large"/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{
                        'li': {
                            fontSize: '18px',
                            padding: '8px 24px',
                            fontWeight: '600'
                        },
                        'a': {
                            color: 'text.primary',
                        }
                    }}
                >

                    {navigations.map((page) => (
                        <MenuItem
                            onClick={handleClose}
                            sx={{

                            }}
                        >
                            <AnchorLink
                                key={page.id}
                                to={`/${page.id}`}
                                title={page.title}
                            >
                                {page.title}
                            </AnchorLink>
                        </MenuItem>
                    ))}

                    <MenuItem onClick={handleClose}>
                        <AnchorLink
                            to={`/exploitdog`}
                            title="ExploitDog"
                        >
                            ExploitDog
                        </AnchorLink>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                        <AnchorLink
                            to="#call"
                            title="Демо показ"
                        >
                            Демо показ
                        </AnchorLink>
                    </MenuItem>
                </Menu>
            </>
        )
    }


    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
        }}>
            <Box
                ml={8}
                sx={{flexGrow: 1, display: 'flex', gap: 3, fontWeight: 600}}
            >
                {navigations.map((page) => (
                    <AnchorLink
                        key={page.id}
                        to={`/${page.id}`}
                        title={page.title}
                    >
                        {page.title}
                    </AnchorLink>
                ))}

                <AnchorLink
                    to={`/exploitdog`}
                    title="ExploitDog"
                >
                    ExploitDog
                </AnchorLink>
            </Box>

            <AnchorLink
                to="#call"
            >
                <Button
                    variant="contained"
                    size="large"
                    sx={{
                        textTransform: 'none'
                    }}
                >
                    Демо показ
                </Button>
            </AnchorLink>
        </Box>
    )
}
