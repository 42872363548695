import React from "react";
import {AppBar, Box, Toolbar, Button, BoxProps} from "@mui/material";
import {LogoIcon} from "../Icons";
import {navigations} from "./constants";
import {Link} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import Navigation from "../common/Navigation";


type Props = {
    sx?: BoxProps["sx"]
}
export default function Header(props: Props) {

    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={0}
            sx={{
                ...props.sx
            }}
        >
            <Toolbar>
                <Link to="/">
                    <LogoIcon
                        htmlColor="#010851"
                        sx={{
                            fontSize: '92px'
                        }}
                    />
                </Link>

                <Navigation/>
            </Toolbar>
        </AppBar>
    )
}
